import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiAccordion: {
      root: {
        "&::before": {
          backgroundColor: "rgb(255 251 251 / 33%) !important",
        },
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "#21262a",
      },
    },
    MuiTableCell: {
      root: {
        border: "1px solid rgb(206 9 39)!important",
      },
      head: {
        color: "#ce0927",
        fontSize: "14px",
      },
      body: {
        color: "#c5c5c5",
        fontSize: "14px",
      },
    },
    MuiOutlinedInput: {
      input: {
        color: "#fff",
        padding: "12.5px 14px",
      },
      root: {
        height: "44px",
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "14px",
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: "#ce0927",
        "&:hover": {
          backgroundColor: "#555",
          // color: "rgba(0, 0, 0, 0.87)",
        },
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        Width: 450,
        maxWidth: "100%",
      },
      paper: {
        overflowY: "unset",
      },
      paperWidthSm: {
        maxWidth: "900px !important",
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },
    MuiMenu: {
      paper: { top: "47px" },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Poppins', sans-serif",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#898989",
      dark: "#de0d0d",
      light: "#de0d0d",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
