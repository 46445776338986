import React, { useState } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  IconButton,
  Button,
  FormHelperText,
  Link,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import TwitterIcon from "@material-ui/icons/Twitter";
import TelegramIcon from "@material-ui/icons/Telegram";
import { FaTelegram, FaFacebookF, FaDiscord, FaMediumM } from "react-icons/fa";
import { GrFacebookOption } from "react-icons/gr";

import { RiDiscordFill } from "react-icons/ri";
import { Link as RouterComponent, useHistory } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import InstagramIcon from "@material-ui/icons/Instagram";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import axios from "axios";
import { toast } from "react-toastify";
import ApiConfig from "src/ApiConfig/ApiConfig";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { BsArrowRight } from "react-icons/bs";
const useStyles = makeStyles((theme) => ({
  footerSection: {
    background: "#2F3235",
    position: "relative",
    padding: "50px 0px 40px 0px",

    backgroundPosition: " bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    "& h6": {
      fontSize: "14px",
      color: "#fff",
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
      },
      "& span": {
        color: "#ce0927",
      },
    },
    "& .follow": {
      color: "#fff",
      display: "flex",
      justifyContent: "center",
      marginTop: "10px",
      "& .links": {
        color: "#fff",
        marginRight: "10px",
        "&:hover": {
          color: "#ce0927",
        },
      },
      "& svg": {
        backgroundColor: "#ce0927",
        color: "#fff",
        borderRadius: "50%",
        width: "35px",
        height: "35px",
        padding: "5px",
      },
    },
    "& h5": {
      // fontWeight: "bold",
      fontSize: "14px",
      // letterSpacing: "2px",
      textTransform: "uppercase",
      color: "#fff",
      textAlign: "center",
    },
    "& ul": {
      paddingLeft: "0",
    },
    "& p": {
      marginBottom: "0px",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "18px",
      color: "#000000",
    },
    "& .logo": {
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
      },
    },
  },
  textFild: {
    position: "relative",
    backgroundColor: "#21262A",
    "& button": {
      position: "absolute",
      top: 0,
      right: 0,
      height: "100%",
      backgroundColor: "#ce0927",
      minWidth: "90px",
      fontSize: "18px",
      fontWeight: "700",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#555",
      },
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "10px",
    },
  },
  footerimg: {
    // marginTop: "60px",
  },

  demo: {
    width: "100%",
    maxWidth: "85%",
    display: "block",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  flexbutton: {
    width: "100%",
    maxWidth: "83%",
    display: "flex",
    "& svg": {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  },
  flex: {
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    maxWidth: "300px",
    margin: "0 auto",
    marginTop: "22px",

    "& .svgbox": {
      display: "inline-block",
    },
  },
  listingbox: {
    border: " 2px solid #ce0927",
    padding: "10px",
    width: "100%",
    maxWidth: "212px",
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  const history = useHistory();
  const [view, setView] = useState(false);
  const [formData, setFormData] = useState("");
  const [open, setOpen] = useState(true);
  const [close, setClose] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  console.log(isSubmit, "isSubmit");
  console.log("formData---", formData);
  console.log(open, "open");

  const handleClick = () => {
    setOpen(false);
    setClose(true);
  };

  const handleFormSubmit = async () => {
    setIsSubmit(true);
    console.log("------");
    if (formData !== "") {
      setIsLoading(true);
      setIsSubmit(false);

      try {
        const response = await axios({
          method: "POST",
          url: ApiConfig.subscribeNow,
          data: {
            email: formData,
          },
        });
        if (response.data.code === 200) {
          toast.success(response.data.message);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    } else {
    }
  };
  return (
    <>
      <Box
        className={classes.footerSection}
        style={{ backgroundImage: "url('./images/footerIMG.png')" }}
      >
        <Box style={{ position: "relative", zIndex: "2" }}>
          <Container maxWidth="lg">
            <Grid container justify="space-around" spacing={1}>
              <Grid item xs={12} sm={3} md={3} lg={3}>
                <Box className="logo">
                  <img
                    alt=""
                    src="images/logo.png"
                    width="100%"
                    style={{ width: "100%", maxWidth: "200px" }}
                  />
                </Box>
                <Box mt={1} mb={2}>
                  <Typography variant="h6">
                    listing@<span>xchangepe</span>.com
                  </Typography>
                </Box>
                <Box mt={1} className={classes.listingbox}>
                  <Link
                    className="links"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSfYrmkfY2JkLPcnTHPG5jZ2iU1AnN17Dqv4Be8RFYu4WnICeQ/viewform?usp=sf_link "
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <Box display="flex" alignItems="center">
                      <Typography variant="h6">Apply for listing</Typography>
                    </Box>
                  </Link>
                </Box>
                <Box mt={1} className={classes.listingbox}>
                  <Link
                    className="links"
                    href="https://t.me/Angel_XChange"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <Box display="flex" alignItems="center">
                      <Typography variant="h6">
                        Become Listing manager
                      </Typography>
                    </Box>
                  </Link>
                </Box>
              </Grid>

              <Grid item xs={12} sm={9} md={5} lg={5} align="center">
                <Typography variant="h5">Find us on Social Media</Typography>
                <Box align="center" mt={3} mb={3} className="follow">
                  <Box className={classes.flex}>
                    <Box className="svgbox">
                      <Link
                        className="links"
                        href="https://t.me/XChangepe_Chat"
                        target="_blank"
                      >
                        <TelegramIcon />
                      </Link>
                    </Box>
                    <Box className="svgbox">
                      <Link
                        className="links"
                        href="https://twitter.com/XChangePe"
                        target="_blank"
                      >
                        <TwitterIcon />
                      </Link>
                    </Box>

                    <Box className="svgbox">
                      <Link
                        className="links"
                        href="https://t.me/XChangepe"
                        target="_blank"
                      >
                        <TelegramIcon />
                      </Link>
                    </Box>
                    <Box className="svgbox">
                      <Link
                        className="links"
                        href="https://medium.com/@XChange"
                        target="_blank"
                      >
                        <FaMediumM />
                      </Link>
                    </Box>
                    <Box className="svgbox">
                      <Link
                        className="links"
                        href="https://nomics.com/assets/xchange-xchangepe "
                        target="_blank"
                      >
                        <img
                          src="images/nomics.png"
                          alt=""
                          width="100%"
                          style={{
                            maxWidth: "45px",
                            borderRadius: "50%",
                          }}
                        />
                      </Link>
                    </Box>
                    <Box className="svgbox">
                      <Link
                        className="links"
                        href="https://www.facebook.com/"
                        target="_blank"
                      >
                        <GrFacebookOption />
                      </Link>
                    </Box>
                    <Box className="svgbox">
                      <Link className="links" target="_blank">
                        <img src="images/cmc.png" alt="" width="45px" />
                      </Link>
                    </Box>

                    <Box className="svgbox">
                      <img
                        src="images/coingigo.png"
                        alt=""
                        width="100%"
                        style={{ maxWidth: "45px" }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} md={3} align="left">
                <Typography variant="h5">
                  we're always happy to help.
                </Typography>
                <Box mt={3} className={classes.textFild}>
                  <TextField
                    id="outlined-basic"
                    fullWidth
                    name="email"
                    variant="outlined"
                    placeholder="Your e-mail"
                    inputStyle={{ color: "white" }}
                    onChange={(e) => setFormData(e.target.value)}
                    error={isSubmit && formData === ""}
                  />

                  <Button
                    variant="contained"
                    type="submit"
                    onClick={handleFormSubmit}
                    disabled={isLoading}
                  >
                    {isLoading && <ButtonCircularProgress />}
                    <ArrowForwardIcon />
                  </Button>
                </Box>
                {isSubmit && formData === "" && (
                  <FormHelperText error>Please enter a email</FormHelperText>
                )}
              </Grid>
            </Grid>
            <Box textAlign="center">
              <p style={{ color: "#fff" }}>
                Copyright © 2022 All Right Reserved.
              </p>
            </Box>
            {/* {open && (
              <Box
                style={{
                  paddingBottom: "0px",
                  position: "fixed",
                  bottom: "0",
                }}
              >
                <Box
                  className={classes.footerimg}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  maxWidth="90%"
                >
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    className={classes.flexbutton}
                  >
                    <IconButton onClick={handleClick}>
                      <CloseIcon style={{ color: "#fff" }} />
                    </IconButton>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  maxWidth="88%"
                >
                  <img
                    src="images/footertwitter.png"
                    alt=""
                    width="100%"
                    className={classes.demo}
                  />
                </Box>
              </Box>
            )}
            {close && (
              <Box>
                <Box className={classes.footerimg}></Box>
                <Box style={{ paddingBottom: "10px" }}></Box>
              </Box>
            )} */}
          </Container>
        </Box>
      </Box>
    </>
  );
}
