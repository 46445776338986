// export const baseURL = "http://172.16.1.172:1927";
// https://node-chaintoken.mobiloitte.org/
export const baseURL = "https://node-chaintoken.mobiloitte.org";

// https://node-chaintoken.mobiloitte.org/
const url = `${baseURL}/api/v1`;
export const websiteName =
  window.location.protocol + "//" + window.location.host;
const Apiconfigs = {
  subscribeNow: `${url}/user/subscribeNow`,
};

export default Apiconfigs;
