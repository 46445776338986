import React from 'react';

const Logo = (props) => {
  return (
    <img
      src="/images/logo.png"
      width="100%"
      style={{ width: "100%", maxWidth: "200px" }}
      alt="Logo"
      {...props}
    />
  );
};

export default Logo;
