export default {
  h1: {
    fontWeight: 500,
    fontSize: 35,
    fontFamily: "'Montserrat', sans-serif",
  },
  h2: {
    fontWeight: 700,
    fontSize: 35,
    color: "#fff",
    marginBottom: 20,
    fontFamily: "'Montserrat', sans-serif",
    "@media (max-width:991px)": {
      fontSize: 35,
    },
    "@media (max-width:767px)": {
      fontSize: 25,
    },
  },
  h3: {
    fontWeight: 500,
    fontSize: 25,
    fontFamily: "'Montserrat', sans-serif",
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
    fontFamily: "'Montserrat', sans-serif",
  },
  h5: {
    fontWeight: 500,
    fontSize: 18,
    fontFamily: "'Montserrat', sans-serif",
  },
  h6: {
    fontWeight: 500,
    fontSize: 16,
    fontFamily: "'Montserrat', sans-serif",
  },
  overline: {
    fontWeight: 500,
    fontFamily: "'Roboto', sans-serif",
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
    fontFamily: "'Roboto', sans-serif",
  },
  body1: {
    color: "#7c7e80",
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "'Roboto', sans-serif",
    "@media (max-width:767px)": {
      fontSize: 12,
    },
  },
  body2: {
    color: "#7c7e80",
    fontWeight: 600,
    fontSize: "18px",
    fontFamily: "'Roboto', sans-serif",
  },
};
